//$color__white-2
#benefit-catalog .nav-pills
.nav-item {
    margin-left: 15px;
}

#benefit-catalog .nav-pills
.nav-item > .nav-link {
    color: $color__white-2    
}

#benefit-catalog .nav-pills
.nav-item > .nav-link {
    &.active {
    color: $color__black-1
    }
}

#benefit-catalog #side-nav {
    &.col-sm-3 {
    padding-right: 0px;
    padding-left: 0px;
    background: $color__blue-3
}
}

.benfCatBelowHeader {
    height: 3em;
    background-color: $color__blue-3;
    border-top: 5px solid $color__teal-1;
    display: flex;
    flex-wrap: wrap;
}

.benfCatBelowHeader-item {
    flex-grow: 1;
}

.printStyle {
    float: right !important;
}

.font-size-18 {
    font-size: 18px;
}

.childSupportStyle {
  margin-right: 10px;
}
.nav-bg {
  background: $color__blue-3;
}
.col-md-9 {
  background: white;
}


.my-benefits-block {
.btn {
    &.link {
        margin-left: auto;
    }
}
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%
}
}
