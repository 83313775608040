/****************************
BLUE THEME - START
****************************/

/** Variables */

/** Font */
$font__base-size: 14px;
$font__family-1: "Roboto-Light", sans-serif;
$font__family-2: "Helvetica Neue", Helvetica, Arial, sans-serif;

/** Colors */
$color__black-1: #3e3e3e;
$color__black-2: #2c3e50;
$color__black-3: #333;
$color__black-4: #142c3f;

$color__white-1: #ffffff;
$color__white-2: #f2f2f2;

$color__teal-1: #46b5c0;
$color__teal-2: #daf0f2;

$color__blue-1: #006293;
$color__blue-2: #337ab7;
$color__blue-3: #1274b2;
$color__blue-4: #4190c1;
$color__blue-5: #0f6397;
$color__blue-6: #85cded;
$color__blue-7: #142c3f;

$color__light-blue-1: #b7d5e8;

$color__dark-1: #142c3f;
$color__dark-2: $color__blue-7;

$color__green-1: #279c38;

$color__yellow-1: #ffcc00;

$color__red-1: #ff0000;

$color__grey-1: #d8dde6;
$color__grey-2: #e6e7e9;

$color__light-grey-1: #ecf4f9;

$color__orange-1: #f79336;

/** Shared Variables */
$font__color-main: $color__black-1;
$font__color-dark: $color__black-1;
$font__color-light: $color__white-2;
$font__color-light-2: $color__white-2;

$nav__color-background: $color__blue-3 !default;
$nav__color-background-active: $color__white-1 !default;
$nav__color-font: $font__color-light;
$nav__color-font-active: $font__color-dark !default;

$header__color-background: $color__blue-3 !default;

$navbar__color-background: $color__blue-3 !default;
$navbar__color_background-2: $color__blue-4 !default;

$navbar__color-hover-1: $color__blue-5 !default;

$link__color: $color__blue-3 !default;

$bg__color-primary-1: $color__blue-3 !default;
$bg__color-primary-2: $color__light-blue-1 !default;
$bg__color-light: $color__white-1 !default;
$bg__color-light-2: $color__white-2 !default;
$bg__color-dark: $color__black-1 !default;
$bg__color-dark-2: $color__black-2 !default;
$bg__color-dark-3: $color__black-4 !default;
$bg__color-light-dark: $color__light-grey-1 !default;
$bg__color-light-dark-2: $color__grey-2 !default;

$button__color-primary-1: $color__blue-3 !default;
$button__color-primary-2: $color__blue-4 !default;

$button__color-hover-1: $color__blue-2 !default;

$icon__star-active: $color__orange-1 !default;

$anchor__primary-light: $color__blue-6;

$color__blue-1: #006293;
$color__blue-2: #337ab7;
$color__blue-3: #1274b2;
$color__blue-4: #4190c1;
$color__blue-5: #0f6397;
$color__blue-6: #85cded;

$bg__color-primary-1: $color__blue-3;
$bg__color-primary-2: $color__light-blue-1;
$bg__color-light: $color__white-1;
$bg__color-light-2: $color__white-2;
$bg__color-dark: $color__black-1;
$bg__color-dark-2: $color__black-2;
$bg__color-dark-3: $color__black-4;
$bg__color-light-dark: $color__light-grey-1;

$nav__color-background: $color__blue-3;
$nav__color-background-active: $color__white-1;
$nav__color-font: $font__color-light;
$nav__color-font-active: $font__color-dark;

$navbar__color-background: $color__blue-3;
$navbar__color-background-2: $color__blue-4;
$navbar__color-hover-1: $color__blue-5;

$button__color-primary-1: $color__blue-3;
$button__color-primary-2: $color__blue-4;
$button__color-hover-1: $color__blue-2;

$link__color: $color__blue-3;

$icon__star-active: $color__orange-1;

$anchor__primary-light: $color__blue-6;
@import "custom-variables";
@import "fonts";
@import "base";
@import "userAccess";
@import "benefitCatalog";
@import "benefit-finder";
@import "client-update-wizard";
@import "my-app-drafts";
@import "cp-renewals";
@import "benH";
@import "banner";
@import "appIntake";
