.card {
  border-radius: 0;
  margin-bottom: 5px;

  .card-header {
    padding: 0;
    background-color: $color__light-blue-1;
    border-radius: 0;
  }

  .card-title {
    margin: 0;
h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 5px 0;
}
    .btn-link{
      font-weight:700;
    }
  }

  .btn-link {
    color:#3e3e3e;
    text-align: left;
    text-transform: capitalize !important;
    padding: 0;

    &:hover {
      color: black;
    }


    // &.collapsed:before {
    //   content: "\F0DA";
    // }
  }
}

.card-group>* {
  width: 100%;

  .card {
    margin-bottom: 5px;
  }

}