#mydrafts-content {
    .glyphicon {
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: Glyphicons Halflings,sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //margin-right: 0;
    }
    
    .glyphicon-folder-open:before {
        content: "\E118";
    }
    
    .glyphicon-pencil:before {
        content: "\270F";
    }
}