.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #f2f2f2;
    background-color: #fff;
    border-style: double;
    border-width: 4px;
  }
  .nav-link{
    >a{
      .row{
        .vcenter{
          display: inline-block;
        vertical-align: middle;
        float: none;
        font-size: 18px;
        color: #f2f2f2;
        }
      }
    }
    
  }
  .nav-pills .nav-item {
    //padding: 10px 15px;
    border-radius: 0;
    &:hover,
    &:focus{
      background-color: transparent;
    }
    .nav-link.active{
      color: #3e3e3e;
      background-color: #fff;
      border-style: double;
      border-width: 4px;
      border-radius: 0;
    &:focus{
      outline-color: #FFFF00 !important;
      outline-width: 3px !important;
      outline-style: solid !important;
      background-color: #fff;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
    .vcenter {
      color: #3e3e3e;
    }
    }
  
  }
  
  // Teja - this has to be re-visit
  .nav-pills {
    display: block;
  }

  #benefits-finder-content .nav-wrapper{
    .nav-pills{
      display: flex !important;
  
    }
  }

  .nav-item {
    #Program-ServiceSelection {
      &.nav-link.active {
      background-color: transparent;
     border-style: none; 
    border-width: none;
    border-radius: none;
        .vcenter {
          color: #fff;;
        }
      }
    }

  }