.condition-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

#user-access-request {
    .panel{
      .card-header{
        padding: 0;
        .btn-link{
            text-align: left;
            font-size: 1.3rem;
            padding: 5px;
            text-decoration: none;
            color: black !important;
            font-weight: 400;
        }
      }
    }
  }