.banner-button {
  background: none !important;
  border: none;
  color: #1274b2;
  text-decoration: underline;
  cursor: pointer;
  font-family: inherit;
  padding: 0 3px !important;
}

.banner-flexbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.banner {
  background-color: rgb(253, 253, 150);
  border-bottom: 1px solid grey;
}

.banner-message {
  flex-grow: 1;
  -ms-flex-positive: 1;
  padding: 8px 0;
}

.banner-close {
  align-items: center;
  -ms-flex-align: center;
  display: flex;
  display: -ms-flexbox;
  margin-right: -10px;
}

.banner-close-button {
  background-color: inherit;
  border: none;
}

.banner-subHeading {
  margin-left: 15px;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 20px;
}

.banner-close {
  .tooltip {
    position: unset;
    opacity: unset;
    
    .tooltip-inner {
      background-color: inherit;
    }
    
    .arrow::before {
      border-right-color: none;
      border-width: 0;
    }
  }
  
  .bs-tooltip-right {
    padding: 0;
  }
}