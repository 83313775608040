// $directory comes from webpack.config file

$icon-font-path: '/fonts/' !default;

@font-face {
  font-family: 'Roboto';
  src: url($icon-font-path + 'Roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url($icon-font-path + 'Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url($icon-font-path + 'glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url($icon-font-path + 'glyphicons-halflings-regular.woff') format('woff'), /* Pretty Modern Browsers */
       url($icon-font-path + 'glyphicons-halflings-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url($icon-font-path + 'glyphicons-halflings-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Glyphicons';
  src: url($icon-font-path + 'glyphicons-regular.eot'); /* IE9 Compat Modes */
  src: url($icon-font-path + 'glyphicons-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url($icon-font-path + 'glyphicons-regular.woff') format('woff'), /* Pretty Modern Browsers */
       url($icon-font-path + 'glyphicons-regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url($icon-font-path + 'glyphicons-regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'FontAwesome';
  src: url($icon-font-path + 'fontawesome-webfont.eot'); /* IE9 Compat Modes */
  src: url($icon-font-path + 'fontawesome-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url($icon-font-path + 'fontawesome-webfont.woff') format('woff'), /* Pretty Modern Browsers */
       url($icon-font-path + 'fontawesome-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
       url($icon-font-path + 'fontawesome-webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}

.glyphicon {
  font-family: 'Glyphicons'
}