// for benefit finder
.icon {
    &.large-bf {
    font-size: 1.9rem;
    }
}

#benefit-content {
    .row {
        margin-left: 0px;
        .paragraph-label {
            margin-left: 0px;
        }
    }
    
    .input-group-prepend {
        padding: 0;
        border: none;
    }
    
    .arrow-right {
        font-size: 13px;
        margin-left: 3px;
        margin-right: 0px;
        font-style: normal;
        font-weight: 700;
    }
    
    .arrow-left {
        font-size: 13px;
        margin-left: 0px;
        margin-right: 3px;
        font-style: normal;
        font-weight: 700;
    }
}

#benefits-finder-content {
    .glyphicon {
        position: relative;
        top: 1px;
        display: inline-block;
        font-family: Glyphicons Halflings,sans-serif;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        //margin-right: 0;
    }
    
    .glyphicon-heart:before {
        content: "\E005";
    }
    
    .glyphicon-cog:before {
        content: "\E019";
    }
    
    .glyphicon-piggy-bank:before {
        content: "\E225";
    }
    
    .myglyphicon-home:before {
        content: "\E021";
    }
    
    .glyphicon-question-sign:before {
        content: "\E085";
    }
    
    .glyphicon-ok:before {
        content: "\E013";
    }
}