
label {
    font-family: $font__family-2;
  }

  .margin-right-5>label,
select,
p {
  margin-right: 5px;
}

.field-string .field-boolean {
    >label{
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
      font-weight: 700;
    }
  }

  legend.legend-label {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: 700;
    width: 100%;
    padding: 0;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: inherit;
    color: #333;
    border: none;
  }
  
  .field-number {
    >label{
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
    }
  }
  
  .field-array {
    >label{
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      display: inline-block;
      max-width: 100%;
      margin-bottom: 5px;
    }
  }