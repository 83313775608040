#client-update-wizard-container {
    .flex-box {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .rjsf {
        width: 100%;
    }
    
    .width-100 {
        width: 100%;
    }
    
    
    .i-agree {
        .checkbox label {
            min-height: 20px;
            padding-left: 20px;
            margin-bottom: 0;
            font-weight: bold;
            cursor: pointer;
          }
          
          .checkbox {
            margin-bottom: 0px;
          }
    }
    
    
    #LifeEventsWizardInfo_application_householdInfo_householdAddress,
    #LifeEventsWizardInfo_application_householdInfo_mailingAddress,
    #LifeEventsWizardInfo_application_householdInfo_contactInfo,
    #RenewalWizardInfo_application_householdInfo_householdAddress,
    #RenewalWizardInfo_application_householdInfo_mailingAddress,
    #RenewalWizardInfo_application_householdInfo_contactInfo
    {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    
    .glyphicon-trash:before {
        font-family: 'Glyphicons Halflings';
        content: "\E020";
    }
   
}

#panel-HealthInsurancePolicy {
    .flex-box {
        > h2 {
            flex: 0 0 100%;
        }
    }
}

#panel-EarnedIncome {
    .flex-box {
        > h2 {
            flex: 0 0 100%;
        }
    }
   .form-group > fieldset {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

#panel-DependentCare {
    .flex-box {
        > h2 {
            flex: 0 0 100%;
        }
    }
   .form-group > fieldset {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

#panel-UtilityCost, #panel-ShelterCost {
    .flex-box {
        > h2 {
            flex: 0 0 100%;
        }
    }
   .form-group > fieldset {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

.flex-column {
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;
    -ms-flex-flow: column;
}

#my-benefits-nav-tabpane-1, #my-benefits-nav-tabpane-3,
#my-benefits-nav-tabs-tabpane-1, #my-benefits-nav-tabs-tabpane-3
{
    .rjsf {
        width: 100%
    }
}