/**
*	IE - Dynamic UI Framework
*/

@function font-url($path, $bool) {
  @return $path;
}

@import '~@optum-wvie/dynamic-ui-framework/src/styles/scss/style';
// @import "~dynamic-ui-shared/styles/style";
@import 'variables';
@import 'mixins';
@import 'cards';
@import 'checkbox-radio';
@import 'nav-pills';
@import 'label';
@import 'errors';

/** CUSTOM GLOBAL **/
/** Mixins */
@mixin icon-left() {
  padding-right: 5px;
}

@mixin icon-right() {
  padding-left: 5px;
}

//

/*
---------------------
Styling organization
---------------------
*/

// Typography =================================================================

@font-face {
  font-family: 'Roboto';
  src: url($icon-font-path+'Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url($icon-font-path+'Roboto/Roboto-Light.ttf');
}

// Navbar =====================================================================

.navbar-header {
  max-width: 200px;
  display: inline-block;
}

.navbar-collapse {
  display: inline-block;
}

.nav-link {
  .nav-text {
    position: relative;
    bottom: 8px;
  }
}

// Buttons ====================================================================

// Tables =====================================================================
.table-spacing {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.table {
  .form-group {
    margin-bottom: 0;
  }

  .checkbox {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .radio {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

div.table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-bordered .thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;

  & .tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;

    & .th {
      display: table-cell;
      text-align: left;
      border: 1px solid #ddd;
      line-height: 1.42857;
      padding: 5px;
      font-weight: bold;

      & legend.span-label {
        font-size: 14px;
        margin: unset;
        border-bottom: unset;
      }
    }
  }
}

.table-bordered .tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;

  & .tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;

    &:nth-of-type(odd) {
      background-color: #f9f9f9;
    }

    & .td {
      display: table-cell;
      border: 1px solid #ddd;
      line-height: 1.42857;
      vertical-align: top;
      padding: 5px;
    }
  }
}

// Forms ======================================================================

.no-info {
  font-style: italic;
}

.form-group {
  fieldset {
    margin-bottom: 5px;
  }

  legend {
    border: none;
    margin-bottom: 4px;
  }
}

// Navs =======================================================================

// Indicators =================================================================

// Links ======================================================================
.link {
  background-color: transparent;
  border: none;
  color: $link__color;

  .active {
    box-shadow: none;
  }

  &:focus,
  &:hover,
  &:active {
    color: $link__color;
    box-shadow: none;
  }

  &.read-more {
    float: right;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e114';

      color: $link__color;
      padding-right: 5px;
    }

    &.active {
      box-shadow: none;

      &::before {
        content: '\e113';
      }
    }
  }

  &.my-benefit {
    position: absolute;
    top: 15px;
    right: 12px;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e006';

      color: $bg__color-primary-2;
      padding-right: 5px;
    }

    &.active {
      box-shadow: none;

      &::before {
        color: $icon__star-active;
      }
    }
  }

  &.scroll-top {
    float: right;

    &::before {
      font-family: $font__family__icon-2;
      content: '\e214';

      padding-right: 5px;
    }
  }
}

.footer-link a {
  color: #98edff;
}

.footer-link a:focus {
  color: #70e1f8;
}

// Containers =================================================================
.main-pane {
  background-color: $bg__color_light;
}

.side-pane {
  color: $font__color_light;

  a {
    color: $font__color_light-2;
  }

  .nav {
    > li {
      > a {
        &:focus,
        &:hover {
          background-color: $bg__color_dark-3;
        }
      }

      &.active {
        > a {
          &:focus,
          &:hover {
            background-color: $bg__color_light;
          }
        }
      }
    }
  }
}

// Panels =====================================================================
.panel-default {
  .panel-heading {
    cursor: auto;
    border-radius: 0;
  }

  .panel {
    border: none;

    & + .panel {
      border: none;
    }
  }
}

/** Panels */
.panel-default {
  .panel-heading {
    background-color: $bg__color_primary-2;
  }

  .panel-title {
    a {
      color: $font__color_dark;
      display: block;
    }

    button {
      color: $font__color_dark;
      display: block;
      background: none;
      border: none;
      padding: unset;
      width: 100%;
      text-align: left;

      &:before {
        font-family: $font__family__icon-3;
        padding-right: 5px;
        content: '\F0D7';
      }

      &.collapsed:before {
        content: '\F0DA';
      }
    }
  }

  .panel-title.title-small {
    font-size: $font-size-small;
  }

  .panel-body {
    background-color: $bg__color_light;
    color: $font__color_dark;

    p {
      margin: 10px;
    }
  }

  .panel {
    .panel-heading {
      background-color: $bg__color_light-dark;
    }
  }
}

.plus-minus-panel {
  .panel-group > .panel-default > .panel-heading {
    background-color: $bg__color-light-dark-2;

    .panel-title {
      a {
        &::before {
          font-family: $font__family__icon-3;
          padding-right: 5px;
          content: '\f056'; // minus
        }

        &.collapsed {
          &::before {
            content: '\f055'; // plus
          }
        }
      }
    }
  }
}

// SPACING ===================================================================
.margin-regular {
  margin: 25px;
}

.bottom-margin-regular {
  margin-bottom: 25px;
}

.sp-md {
  margin: 15px 0;
}

// Misc =======================================================================

.number-highlight {
  background-color: $bg__color-primary-2;
  border-radius: 10px;
  padding: 0 5px;
}

/** ICONS **/
.icon {
  &.large {
    font-size: 3rem;
  }
}

// rm beg
// PAGE - BENEFITS FINDER =======================================================
.benefits-wrapper {
  background-color: $bg__color_dark-2;

  .nav-pills {
    background-color: transparent;
    border: 1px solid $bg__color_dark;
  }
}

// rm end

/** END CUSTOM GLOBAL **/

/** Styling */
/* Main */
body {
  color: $font__color-main;
  font-family: $font__family-1;
  font-size: $font__base-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font__family-1;
  margin-top: 20px;
  margin-bottom: 10px;
}

h5 {
  font-size: 0.9rem;
}

legend {
  font-family: $font__family-1;
}

a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

hr {
  border-top: $bg__color-dark 2px solid;
}

// hr{
// 	margin: 0px;
// 	border-top: none;
// }
header {
  .nav {
    > li {
      > a {
        &:focus,
        &:hover {
          background-color: $navbar__color-hover-1;
        }
      }
    }
  }
}

.main-footer {
  background-color: $color__dark-2;
  padding: 10px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /* For aligning text to bottom */

  .text-bottom {
    vertical-align: bottom;
  }

  /* End: aligining bottom text */

  .h3 {
    color: $font__color-light;
  }

  a {
    color: $anchor__primary-light;
  }

  p,
  span {
    color: $font__color-light;
  }
}

header {
  width: 100%;
}

.header-content {
  max-width: 1200px;
}

.body-content {
  max-width: 1200px;
  overflow: hidden;
}

.feedback-link {
  position: fixed;
  top: 85%;
  right: -45px;
  height: 35px;
  width: 120px;
  transform: rotate(-90deg);
  text-align: center;
  border-radius: 5px 5px 0 0;
  background: $navbar__color-background;
  color: white;
  font-size: 17px;
}

/* Link Styling */
.link {
  background-color: transparent;
  border: none;
  color: $color__blue-3;

  .active {
    box-shadow: none;
  }

  &:focus,
  &:hover,
  &:active {
    color: $color__blue-3;
    box-shadow: none;
  }

  &.read-more {
    float: right;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e114';

      color: $color__blue-3;
      padding-right: 5px;
    }

    &.active {
      box-shadow: none;

      &::before {
        content: '\e113';
      }
    }
  }

  &.my-benefit {
    position: absolute;
    top: 15px;
    right: 12px;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e006';

      color: $color__light-blue-1;
      padding-right: 5px;
    }

    &.active {
      box-shadow: none;

      &::before {
        color: $color__orange-1;
      }
    }
  }

  &.scroll-top {
    float: right;

    &::before {
      font-family: $font__family__icon-2;
      content: '\e214';

      padding-right: 5px;
    }
  }
}

@media screen and (max-width: 720px) {
  .link {
    &.my-benefit {
      position: relative;
    }
  }
}

/* Spacing */

.margin-regular {
  margin: 25px;
}

.bottom-margin-regular {
  margin-bottom: 25px;
}

/* Nav Tabs */
.nav-tabs {
  .glyphicon {
    margin-right: 5px;
  }
}

.nav li:hover {
  cursor: pointer;
}

/* Navbar */
.navbar-brand {
  padding: 5px 15px;

  .navbar-logo {
    max-width: 150px;
  }
}

.navbar {
  background-color: $navbar__color-background;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0;
  align-items: center;

  .home-link {
    line-height: 1em;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e021';
      padding-right: 5px;
    }

    margin-right: 15px;
  }

  .forms-link {
    line-height: 1em;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e032';
      padding-right: 5px;
    }
  }

  .language-button {
    padding: 0px;
    line-height: 1em;
    color: #f2f2f2;
    background: none;
    border: none;
    margin: 15px;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e135';
      padding-right: 5px;
    }
  }

  .sign-in-link {
    line-height: 1em;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e161';
      padding-right: 5px;
    }
  }

  .sign-in-button {
    line-height: 3.5em;
    color: #f2f2f2;
    background: none;
    border: none;

    &:focus {
      border: 2px solid #ffff00;
      padding: 0px;
      line-height: 1em;
      color: #f2f2f2;
      background: none;
      border: none;
      margin: 15px;

      &::before {
        font-family: $font__family__icon-1;
        content: '\e161';
        padding-right: 5px;
      }
    }
  }

  .account-button {
    line-height: 3em;
    padding: 0.5rem;
    line-height: 1em;
    color: #f2f2f2;
    background: none;
    border: none;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e008';
    }

    &::after {
      font-family: $font__family__icon-1;
      content: '\e236';
      padding-right: 5px;
    }

    &:focus {
      border: 2px solid #ffff00;
    }
  }

  .help-link {
    line-height: 1em;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e086';
      padding-right: 5px;
    }
  }

  .nav {
    li {
      a {
        color: $nav__color-font;
      }

      &.active {
        background-color: $bg__color-light;
      }
    }

    li.disabled {
      a {
        &,
        &:focus {
          color: $nav__color-font;
        }

        &,
        &:focus,
        &:hover {
          color: $nav__color-font;
        }
      }
    }
  }

  .navbar-nav {
    margin: 0px;
    flex-direction: row;
    align-items: center;
  }

  .navbar-nav-sign {
    margin: 0px;
    flex-direction: row;
    align-items: center;
  }

  .divider-vertical {
    ::before {
      content: '';
      height: 20px;
      border-left: 1px solid $color__white-1;
      margin-right: 15px;
    }
  }
}

.popover-menu-list {
  list-style: none;
  padding: 0;
  max-width: 125px;

  li {
    border-bottom: 1px solid #000;

    &:last-child {
      border-bottom: none;
    }

    i {
      padding-right: 5px;
    }

    a {
      color: #000;
    }

    .selected {
      font-weight: bold;
      background-color: $color__grey-1;
    }

    > * {
      padding: 10px;
      display: inline-block;
    }
  }
}

.nav-wrapper {
  background-color: $navbar__color-background;
  border-top: 5px solid $navbar__color-background-2;
}

// .nav > li.disabled > a:focus, .nav > li.disabled > a:hover
.nav-pills {
  background-color: $nav__color-background;
  border: 1px solid $nav__color-background;

  > li {
    > a {
      border-radius: 0;
      color: $nav__color-font;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }

    &.active {
      > .btn {
        background-color: $bg__color-light;
        color: $font__color-dark;
        //color: $nav__color-font-active;
        //background-color: $nav__color-background-active;
      }
    }
  }
}

.nav-stacked {
  li {
    & + li {
      margin-top: 0;
    }

    .btn {
      width: 100%;
      text-align: left;
      white-space: normal;
    }
  }
}

.nav-link {
  .glyphicon {
    padding-right: 10px;
  }
}

.badge {
  display: inline-block;
  padding: 3px 7px;
  font-size: 20px;
  width: 35px;
  text-align: center;
  margin-right: 10px;
}

.badge-sm {
  display: inline-block;
  padding: 3px 7px;
  font-size: 12px;
  //width: 35px;
  text-align: center;
  margin-right: 10px;
}

/* Panel Override */

.panel {
  &.icon-heart {
    > .panel-heading {
      .panel-title {
        a::before {
          font-family: $font__family__icon-1;
          content: '\e005';
        }
      }
    }
  }

  &.icon-people {
    > .panel-heading {
      .panel-title {
        a::before {
          font-family: $font__family__icon-2;
          content: '\E025';
        }
      }
    }
  }

  &.icon-apple {
    > .panel-heading {
      .panel-title {
        a::before {
          font-family: $font__family__icon-1;
          content: '\f8ff';
        }
      }
    }
  }
}

.panel-title {
  a {
    font-weight: 700;

    &::before {
      font-family: $font__family__icon-3;

      padding-right: 5px;

      content: '\f0d7'; // caret down
    }

    &.collapsed {
      &::before {
        content: '\f0da'; // caret right
      }
    }
  }

  button {
    font-weight: 700;

    &::before {
      font-family: $font__family__icon-3;

      padding-right: 5px;

      content: '\f0d7'; // caret down
    }

    &.collapsed {
      &::before {
        content: '\f0da'; // caret right
      }
    }
  }
}

.panel-condensed {
  margin-bottom: 0;

  .panel {
    margin-bottom: 0;
  }

  > .panel-collapse {
    > .panel-body {
      padding: 0;
    }
  }
}

[class^='pe-7s-'],
[class*='pe-7s-'] {
  font-size: 25px;
}

.bg-green-alt {
  background: $color__green-1 !important;
  border: 1px solid $color__green-1 !important;
  color: $color__white-1;

  > * {
    color: $color__white-1 !important;
  }
}

.bg-white-alt {
  background: $color__white-1 !important;
  border: 1px solid $color__white-1 !important;

  > * {
    color: $color__black-1 !important;
  }
}

.bg-yellow-alt {
  background: $color__red-1 !important;
  border: 1px solid $color__red-1 !important;
  color: $color__white-1;
  > * {
    color: $color__white-1 !important;
  }
}

.bg-primary-alt {
  background: $bg__color-primary-1 !important;
  border: 1px solid $bg__color-primary-1 !important;
  color: $color__white-1;
}

/* Container Override */

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0;
}

/* Header */

.application-title {
  padding: 10px;
  font-size: 36px;
}

.title-icon {
  color: $color__teal-1;
}

.below-header-bar {
  border-top: 5px solid $color__teal-1;
  background-color: $color__teal-2;
  min-height: 25px;

  .content {
    text-align: right;
  }
}

/* Buttons */
.btn {
  text-decoration: none;

  &:focus,
  &:active,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  .glyphicon {
    margin-right: 10px;
  }

  &.btn-lg {
    font-size: 1.1rem;
  }
}

.btn-primary {
  background-color: $button__color-primary-1 !important;
  border-color: $button__color-primary-2 !important;

  &:hover,
  &:focus,
  &:active:focus {
    background-color: $button__color-hover-1;
    border-color: $button__color-primary-2;
  }
}

.btn-link {
  color: $font__color-light;
  text-decoration: none;

  &:hover {
    color: $link-color;
    text-decoration: none;
  }
}

.btn-link-dark {
  button {
    color: $link__color;
    text-decoration: underline;

    &:hover,
    &:active {
      color: $font__color-dark;
    }
  }
}

.btn-link-down {
  button {
    color: $link__color;

    &::before {
      font-family: $font__family__icon-1;
      content: '\e025';
      padding-right: 5px;
    }
  }
}

.btn-tiny {
  margin: 8px 8px 8px 0px;
  padding: 2px 5px 0px 5px;
}

.btn-white {
  color: $color__blue-3;
  background-color: $color__white-1;

  &:hover {
    color: $color__black-3;
  }
}

.btn-icon-sm {
  margin-right: 4px;
}

.icon-left {
  margin-right: 10px;
}

.margin-regular {
  margin: 15px;
}

/* Emphasis */
.error {
  color: red;
  font-weight: bold;
}

.errors {
  .btn-link {
    color: $link-color;
  }
}

.has-error {
  .form-control {
    outline: 2px solid $color__red-2;
    border-color: $color__red-2;
  }
}

.has-error {
  .Select-control {
    outline: 2px solid $color__red-2;
    border-color: $color__red-2;
  }
}

// below is added for MultiSelectDropdownWidget
.has-error {
  .css-yk16xz-control {
    outline: 2px solid $color__red-2 !important;
    border-color: $color__red-2;
  }
}

// Text color changed to red for checkbox
.has-error .checkbox {
  color: $color__red-2;
}

.has-error .radio {
  color: $color__red-2;
}

button.btn-danger {
  border: 3px solid #b80000 !important;
}

button.btn-danger:focus,
button.btn-danger:hover {
  border: 3px solid #b80000 !important;
  color: #1274b2 !important;
  background-color: #fff !important;
}

/* Main Section */
.tabs-section {
  background-color: $nav__color-background;
}

@media screen and (min-width: $screen-sm-min) {
  .tabs-section {
    padding-bottom: 9999px;
    margin-bottom: -9999px;
  }
}

/* Main Section */
.well-add-section {
  background: inherit;
  background-color: $well-bg;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(4, 166, 199, 1);
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.padding-lr-15x {
  padding: 0px 15px;
}

.padding-top-5x {
  padding-top: 5px;
}

.bold {
  font-weight: bold;
}

.headertxt {
  font-weight: 500;
  color: #2c3e50;
  line-height: 1.4;
}

.reviewpanelquestion {
  font-family: $font__family-1;
  font-weight: 500;
  color: #2c3e50;
  line-height: 1.4;
  font-size: 16px;
}

.reviewpanelanswer {
  font-family: $font__family-3;
  font-weight: 500;
  color: #2c3e50;
  line-height: 1.4;
  font-size: 16px;
}

.readonlyinput {
  background-color: white;
  border: 0px;
}

.info-section {
  border: 1px solid #ccc;
  margin: 10px 0px;
}

.info-section p {
  margin: 0px;
}

.info-section .icon {
  padding: 0px 5px;
  font-size: 15px;
  font-weight: 700;
}

.info-section .text {
  font-size: 15px;
  font-weight: 700;
}

.info-section ul {
  font-size: 12px;
}

.income-info-section {
  padding: 10px 10px;
  position: relative;
  border: 1px solid #04a6c7;
  background: white;
}

/* Carousel */
.home-carousel {
  .carousel-bg-img {
    min-width: 1200px;
  }
}

.carousel-indicators {
  padding-left: 0px;
  bottom: inherit;
  left: 0;
  width: auto;
  margin-left: 0;
  position: relative;

  li {
    display: inline-block;
    margin-left: 5px;
    text-indent: 0;
    background-color: transparent;
    border: none;
    height: auto;
    width: auto;

    &.active {
      background-color: transparent;
      margin-left: 5px;

      button {
        background-color: $bg__color-primary-1;
        width: 15px;
        height: 15px;
      }
    }

    button {
      border: 1px solid $bg__color-primary-1;
      vertical-align: top;
      text-indent: 0;
      width: 15px;
      height: 15px;
      border-radius: 10px;
    }
  }

  .active {
    height: auto;
    width: auto;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 5%;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  filter: drop-shadow(0 0 1px black);
  opacity: 1;
  z-index: 1;
}

.carousel-control-prev {
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
}

.carousel-control-next {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 0))
  );
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
}

.carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9;
}

.carousel-control:focus {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}

.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: '\2039';
}

.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-caption {
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.6);
  position: absolute;
  color: #0c0c10;
  background-color: transparent;
  padding: 0;
  left: 8%;
  top: 0;
  text-align: left;
  margin: 0 auto;
  padding-bottom: 30px;

  .caption-inner {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.carousel-caption-benefits {
  background-color: #fdfcf9;
  margin-top: 14px;

  .listClass {
    a {
      color: $font__color_dark;
    }
  }
}

.carousel-inner {
  overflow: visible;
}

.carousel-play-wrapper {
  display: block;
  position: relative;

  .carousel-play-overlay {
    height: 20px;
    width: 400px;
    top: 89.8%;
    left: 5%;
    position: absolute;
    padding: 0;
    opacity: 1;

    > * {
      display: inline-block;
    }

    > button {
      height: 100%;
      padding: 0 7px;

      color: $color__blue-3;
      border: 1px solid black;
      top: 0;
      vertical-align: top;

      > svg {
        vertical-align: -0.1em;
      }
    }
  }

  .carousel-play-button {
    color: $color__blue-3;
    border: 1px solid black;
    top: 0;
    vertical-align: top;
  }
}

/* Setting min-height on app intake carousel to reduce the amount of re-sizing*/
.prequestionnaire {
  .carousel {
    min-height: 337px;

    @media screen and (max-width: $screen-sm-min), (min-width: $screen-sm-max) {
      .circle {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background: #52b6c0;
      }

      .circle-text {
        width: 100%;

        & div {
          color: #fff;
          font-size: 4em;
          line-height: 1em;
          margin-top: -0.5em;
          float: left;
          width: 100%;
          padding-top: 50%;
          text-align: center;
          text-decoration: underline;
        }
      }
    }

    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      .circle {
        width: 18vw;
        height: 18vw;
        min-width: 130px;
        min-height: 130px;
        border-radius: 50%;
        background: #52b6c0;
      }

      .circle-text {
        width: 100%;

        & div {
          color: #fff;
          font-size: 5vw;
          line-height: 1em;
          margin-top: -0.5em;
          float: left;
          width: 100%;
          padding-top: 50%;
          text-align: center;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .carousel-caption {
    right: 55%;
    top: 5%;
  }
}

/* TODO: set height dynamically */
.dynamic-form {
  min-height: 800px !important;
  overflow: hidden;
}

.list-inline {
  margin: 0 10px 10px;
  list-style: none;
  display: flex;
  justify-content: space-between;

  // li{
  //   display: inline-block;
  //   margin-right: 5px;
  // }
  // li:last-child{
  //   margin-right: 0;
  // }
}

.homelead {
  font-size: 20px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 0px;
  line-height: 1.1;
}

/* Home Page Grid */

.application-grid-margin {
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.application-grid-column {
  position: relative;
}

h2.home {
  font-size: 32px;
}

.ordered-style {
  text-align: left;
  padding-left: 25%;
  list-style-type: disc;
  margin-top: 5px;
}

h6.home {
  font-size: 16px;
  line-height: 1.5;
}

.glyphiconuser {
  color: #ffffff;
  font-size: 80px;
}

/* Apply Now Grid */
hr.applybar {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -15px;
  margin-right: -15px;
  align-content: left;
  border-top: 15px solid #00cc62;
}

.apply {
  font-size: 20px;
  background-color: #66e0a1;
  padding-bottom: 100%;
  margin-bottom: -100%;
}

/* Catalog Grid */
hr.catalogbar {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -15px;
  margin-right: -15px;
  align-content: left;
  border-top: 15px solid #f79336;
}

.catalog {
  background-color: #fabe86;
  border-style: solid;
  border-left: none;
  border-top: none;
  border-bottom: none;
  font-size: 20px;
  border-right: 5px solid #ffffff;
  padding-bottom: 100%;
  margin-bottom: -100%;
}

/* Benefit Finder Grid */
hr.finderbar {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -15px;
  margin-right: -15px;
  align-content: left;
  border-top: 15px solid #3498db;
}

.finder {
  border-style: solid;
  border-left: none;
  border-top: none;
  border-bottom: none;
  background-color: #85c1e9;
  font-size: 20px;
  border-right: 5px solid #ffffff;
  padding-bottom: 100%;
  margin-bottom: -100%;
}

/* My Account Grid */
hr.accountbar {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -15px;
  margin-right: -15px;
  align-content: left;
  border-top: 15px solid #e74c3c;
}

.account {
  border-style: solid;
  border-left: none;
  border-top: none;
  border-bottom: none;
  background-color: #f1948a;
  font-size: 20px;
  border-right: 5px solid #ffffff;
  padding-bottom: 100%;
  margin-bottom: -100%;
}

/* Benefit Catalog */
.benefits-header {
  color: $color__black-2;
  font-family: $font__family-1;
  font-size: 36px;
  margin-top: 20px;
}

.benefitfinderglyphlogo {
  color: #b7d5e8;
  margin-right: 10px;
}

.headerpadding {
  margin-left: 30px;
}

.benefit-tab-txt {
  color: #1274b2;
}

.border {
  border-top: 5px solid #1274b2;
}

.bg-clr {
  background-color: #b7d5e8;
}

.headerContent > li a h2 {
  font-weight: 400;
  color: #1274b2;
  font-size: 24px;
}

.headerContent > li.active a h2 {
  color: #2c3e56;
  background-color: #ffffff;
  font-weight: 800;
  font-size: 24px;
}

.catalog-list-item {
  position: relative;
  display: block;
  padding-right: 5px;
  padding-left: 30px;
  margin-bottom: -1px;
  background-color: #2c3e50;
  color: #f1f1f1;
}

.activate {
  border-top: 10px solid #142c3f;
  background-color: #ffffff;
  color: #2c3e50;
  font-weight: 800;
}

.spacing-table-td {
  padding-left: 10px;
}

.active-category {
  border-bottom: 10px solid #142c3f;
}

.catalog-sublist-item {
  position: relative;
  display: block;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 30px;
  background-color: #ecf4f9;
  color: #1274b2;
  border-bottom: 2px solid #1274b2;
}

.benefit-header3 {
  color: #2c3e50;
  margin-bottom: 10px;
  margin-top: 10px;
}

.middletxt-bold {
  /* font-family: Roboto Bold; */
  font-weight: 500;
  color: #2c3e50;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.4;
}

.catalog-hr {
  border: 2px;
  border-top: 2px solid #1274b2;
  margin-top: 10px;
}

.subheading {
  font-size: 28px;
}

.normaltxt {
  font-weight: 300;
  color: #2c3e50;
  line-height: 1.4;
}

.Bronze-grid {
  padding: 10px;
  background-color: #8f6647;
  margin: 10px;
  height: 210px;
  color: #ffffff;
}

.Silver-grid {
  padding: 10px;
  background-color: #e5e5e5;
  margin: 10px;
  height: 210px;
}

.Gold-grid {
  padding: 10px;
  background-color: #f9d081;
  margin: 10px;
  height: 210px;
}

.Platinum-grid {
  padding: 10px;
  background-color: #5e5e64;
  margin: 10px;
  height: 210px;
  color: #ffffff;
}

.prog-heading {
  text-align: center;
  /* font-family: "Roboto Bold"; */
}

.favbar {
  color: #1274b2;
}

.favoritelabel {
  margin-top: 10px;
}

.favbarinactive {
  color: #b7d5e8;
}

/* Food & Nutrition Table */

td.catalogtxt {
  font-size: 16px;
  text-align: center;
}

.table-spacing {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.table-bordered {
  border: 1px solid #ddd;
}

.snap-table {
  text-align: center;
  margin: 15px;
  border: 1px solid #ccc;
}

/* Benefits Catalog */
.benefits-catalog-content {
  .link.my-benefit {
    float: right;
    position: relative;
    top: 0;
    right: 0;
  }

  .link.my-benefit.btn:focus {
    outline: auto;
  }
}

/* Icons */
.icon {
  &.large {
    font-size: 3rem;
  }
}

/* Misc */
.number-highlight {
  background-color: $color__blue-2;
  border-radius: 10px;
  padding: 0 5px;
}

.my-benefits-block {
  position: relative;

  border: 15px solid $color__white-2;
  border-bottom: 7.5px solid $color__white-2;
  padding: 10px;
  padding-bottom: 25px;

  &:last-child {
    border: 15px solid $color__white-2;
  }

  .title {
    margin: 10px 0;
  }

  .panel {
    border: none;
    box-shadow: none;
  }

  .link.my-benefit.btn:focus {
    outline: auto;
  }
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/** display flex is added to bring nav tab and content on same row **/
.my-account {
  margin-left: 0px;
  display: flex;
}

/*** Accordion Buttons ****/

.padding-lr-5x {
  padding: 0px 5px;
}

.icon-color {
  color: #337ab7;
}

.custom-btn-1x {
  background-color: $color__white-1;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.invisible {
  display: none;
}

.incomeCheckbox {
  margin: 0px;
}

.application-collapse-panel {
  text-align: center;
  background-color: rgb(145, 150, 156);
  margin: 0px 15px 15px 15px;
  width: 100%;
  border: none;
}

.custom-well {
  min-height: 20px;
  padding: 19px;
  background-color: $bg__color-dark-2;
  color: $font__color-light;
  margin-bottom: 0px;

  &.collapsed {
    min-height: 0;
    padding: 0;
  }
}

.colored {
  color: $bg__color-primary-2;
}

.img-carousel {
  margin: 0 40px;
}

.hr-carousel {
  hr {
    border-top: none;
  }
}

.array-item-add {
  margin: 0 15px;
}

.array-item-remove {
  margin-bottom: 15px;
  margin-left: 30px;
}

.width-5 {
  width: 5%;
}

.no-padding-left {
  padding-left: 0px;
}

.hide-hr {
  hr {
    clear: both;
    visibility: hidden;
  }
}

.margin-right-15 > .select-wrapper > select {
  margin-right: 15px;
}

.no-border-header {
  border-left: none;
  border-top: none;
  border-right: none;
}

.no-border-header > thead > tr > th {
  border-left: none;
  border-right: none;
  -webkit-font-smoothing: antialiased;
}

// .mcr {
//   margin-bottom: 0px;
// }

.no-padding-select {
  select {
    padding: 0;
  }
}

button[id^='form1_Application_myMessages_']:hover {
  color: #3e3e3e;
}

/* Client Portal, Food & Nutrition - Content needs to be higher on mobile.*/
@media screen and (max-width: $screen-xs-max) {
  .xs-height-500 {
    height: 500px !important;
  }
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
  font-size: 18px;
}

.my-submit {
  margin-right: 18%;
}

.myProvider_btn {
  color: dodgerblue;
}

.margin-tb-1-warning {
  margin-bottom: 1% !important;
  margin-top: 1% !important;
  color: red;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.form-section {
  .myglyphicon-group {
    margin-right: 5px;
  }

  h1 {
    font-size: 24px;
  }
}

@media screen and (min-width: $screen-lg-min) {
  select.form-control {
    white-space: normal;
    min-width: 80px;
  }
}

@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  select.form-control {
    white-space: normal;
    min-width: 7vw;
  }
}

.select-wrapper select {
  padding: 0px 5px;
}

.select-cp-app > .select-wrapper:after {
  content: none;
}

.heading {
  font-family: $font__family-1;
  font-size: 36px;
  margin-top: 20px;
}

.pull-text-center {
  text-align: center;
}

.checkbox-grid {
  tbody {
    tr:first-child {
      background-color: $bg__color_dark-2 !important;

      td {
        border: 0px;
      }
    }

    input[type='checkbox'] {
      position: relative;
      margin-left: 0px;
    }
  }
}

// Vericication Modal
.large-verification-icon {
  font-size: 20rem;
}

.green {
  color: $color__green;
}

.red {
  color: $color__red;
}

.select-role-tile {
  width: 245px;
  height: 245px;
  background: inherit;
  background-color: rgba(218, 228, 243, 0);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(212, 222, 224, 1);
  border-radius: 0px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);

  &:hover {
    background-color: rgba(218, 228, 243, 1);
    border: none;
  }
}

.select-role-tile-selected {
  @extend .select-role-tile;
  background-color: rgba(218, 228, 243, 1);
  border: none;
}

.select-role-label {
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 20px;
  text-align: center;
}

.select-role-org-label {
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 15px;
  text-align: center;
}

.select-role-container {
  position: relative;
  width: 100%;
  min-height: 700px;
  // border: 2px dashed;
}

.select-role-signin {
  font-size: 20px;
  margin-top: 40px;
}

.default-role {
  position: absolute;
  bottom: 10%;
  left: 0%;
  width: 100%;
  // height: 10px;
  // margin:0px 10px 0px 20px;
  // border: 2px solid;
}

.top-buffer {
  margin-top: 10px;
}

.classWithPad {
  margin-right: 20px;
  padding: 20px 20px 0px 20px;
}

.pe-7s-icon-90 {
  font-size: 90px;
}

.provider-mapping-headerRow {
  background-color: #333333;
  color: #ffffff;
}

.border-1px {
  border: 1px solid $dropdown-fallback-border;
}

.header-popover-panels {
  .panel {
    margin-bottom: 5px;

    .panel-body {
      padding: 5px;

      .selected {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}

.popover-button {
  border: none;
  background-color: inherit;
}

#accountPopover {
  &.popover {
    max-width: 125px;
  }
}

#rolesPopover {
  &.popover {
    &.right {
      .arrow {
        top: 20px;
      }
    }
  }
}

.popover {
  border-radius: 0;
}

.popover-content {
  padding: 0;
}

.border-bottom-1px {
  border-bottom: 1px solid $color__grey-1;
}

.popover-md {
  width: 276px;
}

.read-only-table {
  td > div.form-group {
    margin-right: 10px;
  }
}

.btn.btn-link.hover-black:hover,
.btn.btn-link.hover-black:focus,
.btn.btn-link.hover-black:active {
  color: $bg__color_dark-2;
}

.long-link {
  white-space: normal;
  text-align: left;
}

.required-asterisk {
  font-size: 1.4em;
  line-height: 0;
}

.home-cards {
  display: flex;
  margin: 0;
  margin-bottom: 5px;
  flex-wrap: wrap;
  width: 100%;

  .btn {
    margin: 0 auto;
    min-width: 75px;
  }

  > .col {
    border-right: 5px solid #fff;
    flex-basis: auto;
  }

  > .col:last-child {
    border-right: none;
  }

  .glyphicons {
    margin: 0 auto;
  }
}

.form-group fieldset {
  margin-bottom: 5px;
}
fieldset,
legend {
  padding: 0;
  border: 0;
}
fieldset {
  margin: 0;
  min-width: 0;
}

.form-group legend {
  border: none;
  margin-bottom: 4px;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.legend-replacement sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Dollar Widget $ addon */
.input-group-prepend {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/** Setting Carousel Table title => top  */

.carousel-item {
  .paragraph-label {
    caption-side: top;
  }
}

.container-fluid {
  .no-padding {
    padding-left: 0px !important;
  }
}

.hidden {
  display: none;
}
/** My Applications button **/
.justify-content-end {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  float: right !important;
}

.myglyphicon-apple:before {
  content: '\F8FF';
  font-family: 'Glyphicons Halflings';
}

.default-navtabs {
  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  .nav-item {
    .badge {
      margin: 0;
      border-radius: 10px;
      flex: none;
      max-height: 29px;
    }

    .nav-link {
      .glyphicon {
        padding: 0;
      }

      .nav-text {
        bottom: 0;
      }
    }
  }
}

.form-control {
  font-size: inherit;
}

.margin-left-minus-15 {
  margin-left: -15px;
}

.navbar-brand {
  font-size: 18px;
  height: 50px;
}

.nav span {
  font-size: 18px;
}

.navbar-home {
  flex-grow: 1;
  -ms-flex-positive: 1;
}

.img-fluid {
  max-width: 100%;
  height: 100%;
}

h1 {
  font-size: 36px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}

.width-100 {
  width: 100%;
}

.instruction-panel {
  .card {
    background-color: #2c3e50;
    color: #f2f2f2;
    margin-bottom: 0;

    p {
      margin: 3px 0px;
    }

    .card-header {
      display: none;
    }

    .badge {
      background-color: #777;
      border-radius: 10px;
    }
  }
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}
.margin-bottom-minus-15 {
  margin-bottom: -15px;
}

.flex-grow-1 {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.offset-right-6 {
  margin-right: 50%;
}

.offset-right-3 {
  margin-right: 25%;
}

.offset-right-1 {
  margin-right: 8.333333%;
}

#my-applications {
  @media screen and (min-width: 1000px) {
    .app-flex-right {
      flex-grow: 1;
      -ms-flex-positive: 1;
      margin-left: 67%;
    }
  }
}

.margin-left-20 {
  margin-left: 20px;
}

.hide {
  display: none;
}

/* below is for after SubmissionModal.tsx*/
.myglyphicon-folder-open:before {
  font-family: 'Glyphicons Halflings';
  content: '\E118';
}

.hyper-link-color {
  color: #337ab7;
}

/*below will overide _modal.css */
@media (min-width: 768px) {
  .modal-lg {
    max-width: 600px !important;
    margin: 30px auto !important;
  }

  .modal-sm {
    max-width: 300px;
    margin: 30px auto;
  }
}

@media (min-width: 576px) {
  .modal-lg {
    max-width: 600px !important;
    margin: 30px auto !important;
  }
  modal-sm {
    max-width: 300px;
    margin: 30px auto;
  }
}

.modal-footer {
  margin-top: -15px;
  border-top: none;
  padding: 0 0.75rem 0.75rem 0.75rem;
}

.modal-footer {
  margin-top: 0px;
  border-top: 1px solid #dee2e6;
  padding: 0 0.75rem 0.75rem 0.75rem;
}

// Below is the modal size for large
@media (min-width: 768px) {
  .modal-xl {
    max-width: 900px;
    margin: 30px auto;
  }
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: 900px;
    margin: 30px auto;
  }
}

.flex-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/** default property for button**/
.btn {
  font-size: 14px;
}

/** **/
.glyphicon-trash:before {
  font-family: 'Glyphicons Halflings';
  content: '\E020';
}

/** **/
.plus-minus-panel {
  width: 100%;
}

#account-menu {
  margin-bottom: 0;
}

#child-care {
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
}

.margin-left-minus-15 {
  margin-left: -15px;
}

.margin-top-minus-4 {
  margin-top: -4px;
}

.flex-auto {
  flex: 1 1 auto;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.mtb-20 {
  margin: 20px 0;
}

@media only screen and (min-width: 768px) {
  .col-lg-width-19 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    flex-basis: 20%;
    max-width: 20%;
  }
}

@media only screen and (max-width: 539px) {
  .navbar {
    background-color: $navbar__color-background;
    margin-bottom: 0;
    border-radius: 0;
    padding: 0;
    align-items: center;

    .navbar-nav-sign {
      margin: 0px;
      flex-direction: column;
      align-items: center;
    }

    .margin-left-15px {
      margin-left: 15px;
    }

    .margin-yl-minus-10px {
      margin-top: -10px;
      margin-left: -10px;
    }
  }
}

.btn-light2 {
  padding: 0 5px !important;
}

.screen-results {
  margin-bottom: 3rem;
  margin-top: 2rem;
  margin-right: 1rem;
}


.action-btns{
  .btn{
      margin-left: 10px;
  }
  
}

// PRELOADER styles some how shared styles are not loading
.spl-wrapper {
  background-color: black;
  opacity: 0.5;
  z-index: 100;
  display: block;
  min-height: 500px;


  .spl {
    /* margin: 100px auto; */
    margin: auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    &.hide {
      display: none;
    }
  }
}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}