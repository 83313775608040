#benHEligibleApplicant,
#benHReferral,
#bannerMessage {
  #root__title {
    background-color: #1274b2;
    font-size: 20px;
    color: white;
    margin-left: 15px;
    margin-right: 15px;
  }

  .searchListButtonDiv {
    margin-bottom: 10px;
  }

  #searchTableTitle {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 16px;
  }

  .userListButton {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 200;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    background-color: #337ab7;
    margin-left: 10px;
    color: #fff;
  }

  .userListButton1 {
    display: inline-block;
    margin-bottom: 2em;
    font-weight: 200;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    background-color: #fff;
    margin-left: 15px;
    color: #333;
    border-color: #444;
  }

  .userListButton2 {
    display: inline-block;
    margin-bottom: 2em;
    font-weight: 200;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    background-color: #337ab7;
    margin-left: 10px;
    color: #fff;
  }

  .margin-top-minus-10 {
    margin-top: -10px;
  }

  .flex-box {
    > h1 {
      flex: 0 0 100%;
    }
  }

  #root_Application_comments {
    .card {
      .flex-box {
        > hr {
          border-top: none !important;
          margin-top: 0;
          border: none;
        }
      }
    }
  }

  .array-field-separator {
    border-top: none !important;
    margin-top: 0;
    border: none !important;
  }

  .benh-danger {
    border: 1px solid #b80000 !important;
    background-color: #d4403a !important;
    color: #fff !important;
  }
}

#root_programList {
  input[type="checkbox"] {
    margin-left: 0px;
  }

  .checkbox {
    a {
      color: #1274b2;
      text-decoration: underline 1px solid;
    }
  }
}

#root_demographicInformation__description {
  flex: 1 0 100%;
}

#banner-errors {
  margin-left: 15px;
  margin-right: 15px;
}
