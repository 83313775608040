.panel-danger {
    &.errors{
        border: solid 2px $state-danger-border;
        border-radius: .25rem;
        .panel-heading {
            color: $gray-base;
            background-color: $state-danger-bg;
            border-color: $state-danger-border;
            padding: 10px 15px;
            border-bottom: 1px solid transparent;
            border-top-right-radius: -1;
            border-top-left-radius: -1;
            .panel-title{
                margin-top: 0;
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                }
            }
        >ul{
            margin-bottom: 0;
            >div{
                >li{
                    border-bottom: 0 !important;
                    border-bottom-right-radius: -1;
                    border-bottom-left-radius: -1;
                    border:none;
                    >a {
                        color: #337ab7;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.has-error .form-control {
    outline: 2px solid #a94442;
}

.text-danger{
    font-weight: 600;
    font-family: Roboto, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: $state-danger-text;
}

.field-error{
    .form-group{
        margin-bottom: 0;
    }
}

.panel-danger{
    border: $state-danger-border solid 1px;

    .panel-heading{
        background: $state-danger-bg;
    }
}
